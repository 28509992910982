/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css";
@import url("https://fonts.googleapis.com/css2?family=Francois+One&display=swap");
* {
  padding: 0;
  box-sizing: border-box;
}
input.form-control {
  font-size: 13px;
  color: #000000;
}
@font-face {
  font-family: "AvenirLTStd45Book";
  src: url("./assets/font/AvenirLTStd-Book.woff2") format("woff2"),
    url("./assets/font/AvenirLTStd-Book.woff") format("woff"),
    url("./assets/font/AvenirLTStd-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "AvenirLTStd55Roman";
  src: url("./assets/font/AvenirLTStd-Heavy.woff2") format("woff2"),
    url("./assets/font/AvenirLTStd-Heavy.woff") format("woff"),
    url("./assets/font/AvenirLTStd-Heavy.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "AvenirLTSt65";
  src: url("./assets/font/AvenirLTStd-Black.woff2") format("woff2"),
    url("./assets/font/AvenirLTStd-Black.woff") format("woff"),
    url("./assets/font/AvenirLTStd-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "amerigo_btroman";
  src: url("/assets/font/amerigo_bt-webfont.woff2") format("woff2"),
    url("/assets/font/amerigo_bt-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gabriola";
  src: url("/assets/font/Gabriola.eot");
  src: url("/assets/font/Gabriola.eot?#iefix") format("embedded-opentype"),
    url("/assets/font/Gabriola.woff2") format("woff2"),
    url("/assets/font/Gabriola.woff") format("woff"),
    url("/assets/font/Gabriola.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "metropolislight";
  src: url("/assets/font/metropolis.light-webfont.woff2") format("woff2"),
    url("/assets/font/metropolis.light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "metropolisregular";
  src: url("/assets/font/metropolis.regular-webfont.woff2") format("woff2"),
    url("/assets/font/metropolis.regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "metropolisbold";
  src: url("/assets/font/metropolis.bold-webfont.woff2") format("woff2"),
    url("/assets/font/metropolis.bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "gothambook";
  src: url("/assets/font/gothambook-webfont.woff2") format("woff2"),
    url("/assets/font/gothambook-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.bg-light-blue {
  background: #f2fcff;
}
.btn {
  min-height: 52px;
}

body {
  width: 100%;
  height: 100%;
  background-image: url("./assets/img/banner_background.png");
  background-size: cover;
  background-attachment: fixed;
  margin: 0;
  font-size: 1rem;
  padding: 0px;
  font-family: AvenirLTStd45Book;
}

.flex-1 {
  flex: 1;
}

@media (min-width: 992px) {
  .flex-lg-1 {
    flex: 1 !important;
  }
}

.coming-soon {
  display: inline-block;
  // border: 5px solid rgb(238 238 238 / 57%);
  border-radius: 5px;
  max-width: 700px;
}
.coming-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
}
// universal clases
.cursor-pointer {
  cursor: pointer;
}

// buttons
.custom-btn {
  max-width: 320px;
}

.btn {
  font-weight: 600;
}

.button-primary {
  background-color: #00b2bb;
  color: #ffffff;
  height: 50px;
  border-radius: 7px;
  border: 2px solid #00b2bb;

  &:hover {
    border: 2px solid #00b2bb;
  }
}

.button-primary-outline {
  background-color: #ffffff;
  color: #696969;
  height: 50px;
  border-radius: 7px;
  border: 2px solid #00b2bb;

  &:hover {
    color: #696969;
  }
}

// common style

.form-control {
  min-height: 50px;
}

.img-preview {
  max-width: 90%;
  max-height: 100%;
  object-fit: cover;
}

.fileBrowsee {
  position: absolute;
  z-index: 9;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.imgBox {
  padding: 15px;
  border: 1px solid #1db2bb;
  border-radius: 15px;
  position: relative;
  width: 335px;
  height: 254px;
  background: var(--white);
  margin: auto;
  .img-preview {
    max-height: 180px !important;
  }
}
.calender-input {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
}

.card.cardvaccinrecord {
  width: 335px;
  height: 238px;
  background-image: url("./assets/img/background_dose.png");
  border-radius: 15px;
  background-size: cover;
  background-color: transparent;
  background-repeat: no-repeat;
}
.content-wrapper {
  min-height: calc(100vh - 147px);
  padding-top: 16px;
  width: calc(100% - 90px);
  margin-left: 90px;
  // width: calc(100% - 230px);
  // margin-left: 230px;
}
.widthout-login {
  .content-wrapper {
    width: 100% !important;
    margin-left: 0px !important;
  }
}
.login-wrapper {
  height: calc(100vh - 89px);
  @media (max-width: 992px) {
    height: calc(100vh - 73px);
  }
  @media (max-width: 576px) {
    height: calc(100vh - 60px);
  }

  .content-wrapper {
    width: calc(100% - 230px) !important;
    margin-left: 230px !important;
  }
}
a {
  text-decoration: none !important;
}
:root {
  --green: #1db2bb;
  --lightgreen: #00b2bb;
  --black: #0f1941;
  --olive: #8dcf36;
  --grey: #707070;
  --darkgrey: #282c2c;
  --white: #ffffff;
}

.wrap_first h1 {
  font: normal normal normal 31px/46px Francois One;
  letter-spacing: 1.59px;
  color: #282c2c;
  text-transform: uppercase;
}

a.btnaddVacine,
a.btncreateAccount,
button.btnaddVacine {
  border-radius: 7px;
  padding: 8px 28px;
  color: #fff;
  display: grid;
  place-content: center;
  text-transform: uppercase;
  font: normal normal normal 13px/26px AvenirLTStd45Book;
  border-color: transparent;
}

a.btnaddVacine span,
a.btncreateAccount span {
  font-size: 12px;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  font: normal normal normal 13px/26px AvenirLTStd45Book;
  letter-spacing: 0px;
  opacity: 1;
  margin: 0 10px;
}

a.btnaddVacine,
button.btnaddVacine {
  background: var(--lightgreen);
}

a.btncreateAccount {
  background: var(--olive);
}

.form-control:focus {
  border-color: none !important;
  box-shadow: none !important;
}

button.btnaddVacine {
  font: normal normal 900 12px/20px AvenirLTStd45Book;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
}

.backbtn {
  cursor: pointer;
}

button:focus {
  outline: 0px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}

.sidepanel {
  width: 30%;
  z-index: 99;
  height: 600px;
  top: 0;
  left: 0;
  overflow-x: unset;
  transition: 0.5s;
  position: absolute;
  float: left;
  border-radius: 0px 20px 20px 0px;
  padding-top: 80px;
  background: #edfeff;
}

.sidepanel a {
  display: block;
  transition: 0.3s;
  font: normal normal 900 14px/30px AvenirLTStd45Book;
  letter-spacing: 0px;
  color: #5a5a5a;
  padding: 8px;
}

span.text-danger.error {
  margin: 12px 0;
  font: normal normal 900 12px/30px AvenirLTStd45Book;
}

.wrap_both {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #c2ecef;
}

.sidepanel a.borderline:before {
  content: "";
  background: #8dcf36;
  height: 6px;
  width: 100%;
  border-left: 4px solid #8dcf36;
  margin-right: 25px;
  border-radius: 25px;
}

.bodyy .closebtn {
  position: absolute;
  top: 10px;
  left: 27%;
  z-index: 999;
}

.wrap_first h3 {
  font: normal normal normal 24px/50px Francois One;
  letter-spacing: 0.72px;
  color: #282c2c;
  text-transform: uppercase;
  text-align: center;
}

.modal-body {
  padding: 0;
  border-radius: 18px;
}

.bodyy {
  background: rgba(11, 20, 44, 0.62);
  width: 100%;
  z-index: 99;
  height: 600px;
  top: 0;
  left: 0;
  transition: 0.5s;
  position: absolute;
}

.wrapper_for_box {
  background: rgb(240 254 255);
}

.modal-content {
  border: 0px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 18px !important;
}

//  calenderdesign

img.calenderdesign {
  position: absolute;
  right: 11px;
  top: 11px;

  cursor: pointer;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  position: relative;
  z-index: 9;
}

.calenderset {
  position: relative;
  // z-index: 9;
  background: #f8f8f8;
  border-radius: 7px;
  border: 0;
  padding: 0;
}

.fade.show {
  background: rgba(60, 61, 54, 0.5);
}

footer {
  width: 100%;
  // position: absolute;
  // bottom: 0rem;
  // left: 0;
  // right: 0;
  font-family: "metropolisregular";
}

// page heading
.modal-header h4 {
  margin: 0px;
  font-family: "AvenirLTStd55Roman";
  font-size: 1.5rem;
}
.modal-footer .btn {
  padding: 0.55rem 1.5rem;
  min-height: auto;
}
.modal-footer .btn-danger {
  background: #00b2bb;
  border-color: #00b2bb;
}
.modal-footer .btn-danger:focus,
.modal-footer .btn-danger:hover {
  background: #00a0a8;
  border-color: #00a0a8;
}
.modal-footer .btn-primary {
  background: #555;
  border-color: #555;
}
.modal-footer .btn-primary:focus,
.modal-footer .btn-primary:hover {
  background: #333;
  border-color: #333;
}

.upload-card-custom {
  img {
    max-height: 160px !important;
  }
}
.upload-card {
  padding: 15px;
  border: 1px solid #1db2bb;
  border-radius: 15px;
  position: relative;
  max-width: 335px;
  height: 254px;
  background: var(--white);
  text-align: center;
  margin: auto;
  img {
    max-width: 200px;
  }
  input {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 99;
    left: 0;
    top: 0;
    cursor: pointer;
    opacity: 0;
  }
  .option-drop {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 9999;
    padding: 20px;
  }
}
.row1 {
  margin-left: -7px;
  margin-right: -7px;
  [class*="col-"] {
    padding-left: 7px;
    padding-right: 7px;
  }
}
.page-heading {
  text-align: center;
  text-transform: uppercase;
  font-size: 31px;
  font-family: Francois One;
}
.wrap_first {
  display: flex;
  flex-direction: column;
  // row-gap: 15px;
}
.exceed-date {
  background: #ff686b;
  color: #fff;
  svg path {
    fill: #fff;
  }
}
.vaac-image-img1 img {
  max-height: 85px;
}
.buttondesign {
  max-width: 270px;
  margin: auto;
}
.vaac-image-mx {
  max-height: 85px;
  max-width: 85px;
  width: auto !important;
}
.mxw-100 {
  max-width: 100%;
}
.wrap_first h3.mb-0-n {
  margin-bottom: 0px !important;
}
.pin-img {
  min-height: 43px;
}

// .panel-group{background: red;}
.about-list {
  .panel {
    margin-bottom: 1rem;
    border: none;
    background: #f7f7f7;
    border-radius: 6px;
    .panel-heading {
      padding: 0;
      border: none;
      background: none;
      .btn {
        min-height: auto;
        height: auto !important;
        width: 100%;
        text-align: left;
        padding: 1rem 1.25rem;
        background: none;
        color: #707070;
        text-decoration: none;
        box-shadow: none !important;
        position: relative;
      }
      &::after {
        content: "\f107";
        font: normal normal normal 14px/1 FontAwesome;
        position: absolute;
        right: 1rem;
        font-size: 1.1rem;
        height: 30px;
        width: 30px;
        background: #00abb7;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--white);
        top: 13px;
        border: 1px solid #00abb7;
        transition: 0.3s;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        -moz-transition: 0.3s;
        -ms-transition: 0.3s;
      }
    }
    .panel-body {
      border-top: 1px solid #eee;
    }
  }
  .panel-open {
    .panel-heading {
      &::after {
        background: var(--white);
        color: #00abb7;
        transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
      }
    }
  }
}
.text-gray {
  color: #888 !important;
  font-family: "AvenirLTStd45Book";
  font-size: 1rem;
  line-height: 24px;
}
.form-group.passwordBox img {
  position: absolute;
  /* top: 50%;
    transform: translateY(-50%);
    right: 8px; */
  top: 13px;
  right: 12px;
}
form.form_design label {
  font: normal normal 900 12px/30px AvenirLTStd45Book;
  letter-spacing: 0px;
  color: #282c2c;
  text-transform: capitalize;
  margin-bottom: 0;
}
.form-group.passwordBox {
  position: relative;
}
.row1 {
  margin-left: -10px;
  margin-right: -10px;
  [class*="col-"] {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.socail-icons svg {
  width: 38px;
  height: 38px;
  border-radius: 50%;
}
/* media queries */
@media (max-width: 992px) {
  .container {
    max-width: 800px;
  }
  // .login-wrapper .content-wrapper {
  //     width: calc(100% - 220px) !important;
  //     margin-left: 220px !important;
  // }
  // .my-vaccine-card-wrapper{
  //     .left-tabs{width: 155px !important;}
  //     .left-nav {
  //         .items {
  //             .item {
  //                 padding: 20px 21px !important;
  //                 font-size: 14px !important;
  //             }
  //             .item.active::before {
  //                 left: 9px !important;
  //             }
  //         }
  //     }
  //     .right-container {
  //         width: calc(100% - 155px) !important;
  //     }
  // }
}
@media screen and (max-width: 767px) {
  .coming-center {
    // height: calc(100vh - 230px);
  }
  .login-wrapper {
    .content-wrapper {
      width: 100% !important;
      margin-left: 0px !important;
    }
  }
  .text-gray {
    font-size: 0.9rem;
    line-height: 24px;
  }
  .about-list {
    .panel {
      .panel-heading {
        .btn {
          padding: 0.8rem 1.25rem;
        }
        &::after {
          height: 26px;
          width: 26px;
          font-size: 1rem;
        }
      }
    }
  }
  body {
    font-size: 0.95rem;
  }
  .btn,
  a.btnaddVacine,
  a.btnaddVacine span,
  a.btncreateAccount {
    font-size: 15px;
    height: 45px !important;
    min-height: 45px;
  }
  a.btncreateAccount span {
    font-size: 15px;
  }
  .content-wrapper,
  .widthout-login .content-wrapper {
    width: 100% !important;
    margin-left: 0 !important;
  }

  .mobile-navbar {
    .navbar {
      background: none !important;
      padding: 0px;
      button {
        position: absolute;
        right: 0;
        top: 13px;
        border: none;
      }
    }
  }

  .wrap_first h1 {
    letter-spacing: 0px !important;
    font: normal normal normal 22px/34px Francois One !important;
    margin-bottom: 10px !important;
  }
  .container .wrap_first h3 {
    margin-bottom: 20px !important;
  }
  button.btnaddVacine.widthbtnsignup {
    width: -webkit-fill-available !important;
  }

  .wrap_first h1,
  .wrap_button span,
  a.btnaddVacine,
  button.btnaddVacine,
  a.btncreateAccount {
    text-align: center;
    width: -webkit-fill-available !important;
  }

  .wrap_button {
    display: flex;
    flex-direction: column !important;
  }

  .sidepanel {
    width: 100%;

    border-radius: 0;
  }

  .bodyy .closebtn {
    right: 0px;
    left: 13px;
    position: relative;
  }

  .sidepanel a {
    font: normal normal 900 11px/30px AvenirLTStd45Book;
  }

  .bodyy {
    height: auto;
  }

  .custom-btn {
    max-width: auto;
  }
}
body {
  margin: 0;
  // font-family: Roboto, "Helvetica Neue", sans-serif;
}
table,
input.form-control {
  font-family: AvenirLTStd45Book !important;
}
input.form-control,
input.form-control::placeholder {
  font-size: 15px;
}
input.form-control,
input.form-control::placeholder {
  font-size: 15px !important;
}
.mat-body,
.mat-body-1,
.mat-typography {
  font-family: AvenirLTStd45Book !important;
}
form.form_design label {
  font: normal normal 900 16px/30px AvenirLTStd45Book !important;
}
input.form-control {
  font-size: 15px !important;
}
// .form-custom .form-control, .calenderset{margin-bottom: 20px;}
// .form-custom .calenderset .form-control{margin-bottom: 0px !important;}
.wrap_first h3 {
  margin-bottom: 24px !important;
}
.wrap_first h1 {
  margin-bottom: 15px !important;
}

.cstom-cntry-list > mat-select-country > .mat-mdc-form-field {
  width: 100%;
}
.cstom-cntry-list .mat-form-field-infix {
  padding: 16px 5px;
  margin: 0px !important;
  height: auto !important;
  width: 100% !important;
  border: none !important;
  min-height: 50px;
}
.cstom-cntry-list mat-label.ng-tns-c145-0.ng-star-inserted {
  top: 0px !important;
  position: relative;
  left: 5px;
}
* .cstom-cntry-list .mat-input-element {
  font-size: 16px;
}
.cstom-cntry-list .mat-form-field-appearance-outline .mat-form-field-outline {
  background: #f8f8f8;
  border-radius: 5px;
  border: 1px solid #f8f8f8;
  top: 0px !important;
}
.cstom-cntry-list
  .mat-form-field-appearance-outline
  .mat-form-field-outline-end,
.cstom-cntry-list
  .mat-form-field-appearance-outline
  .mat-form-field-outline-start,
.cstom-cntry-list
  .mat-form-field-appearance-outline
  .mat-form-field-outline-gap {
  border: none;
}
.cstom-cntry-list
  .mat-form-field-appearance-outline
  .mat-form-field-outline:after {
  content: "";
  position: absolute;
  right: 10px;
  font-size: 20px;
  top: 20px;
  border-left: 7px solid transparent;
  border-top: 8px solid #0f3536;
  border-right: 7px solid transparent;
}
.cstom-cntry-list .mat-form-field-appearance-outline .mat-form-field-prefix,
.mat-form-field-appearance-outline .mat-form-field-suffix {
  top: -1px !important;
}
.cstom-cntry-list .mat-form-field-appearance-outline .mat-form-field-label {
  top: 27px;
}
.cstom-cntry-list
  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float
  .mat-input-server:focus
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  transform: translateY(0em) scale(0.75);
  opacity: 0;
}
.cstom-cntry-list .ng-untouched {
  width: 100%;
  display: block !important;
}
.cstom-cntry-list .mat-form-field-wrapper {
  margin: 0px;
  padding: 0px;
}
.mat-form-field {
  width: 100%;
}
.cstom-checkbox::before {
  background: #fff;
  border-color: #ccc !important;
  left: 0 !important;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  box-shadow: none !important;
}
// .custom-checkbox input:checked ~ .cstom-checkbox::after {
//   background: #8dcf36;
//   border-color: #8dcf36;
//   background-repeat: no-repeat;
//   background-position: center;
//   left: 0;
//   border-radius: 5px;
//   background-size: 11px;
// }
// .cstom-checkbox::after {
//   left: 1px;
//   height: 20px;
//   width: 20px;
// }
.custom-checkbox .custom-control-label {
  padding-left: 2rem;
  cursor: pointer;
}
.btn-underline {
  color: #1db2bb;
  font-weight: 600;
  text-decoration: underline;
}
.modal-light-blue {
  background: #f0feff;
}
.modal-light-blue .modal-content {
  background: #f0feff;
}
button.close {
  cursor: pointer;
}
.dashabord-img {
  max-width: 100%;
}
.text-green {
  color: #1db2bb;
}
.forgot-subheading {
  font-size: 18px !important;
}
.bg-light-blue {
  background: #f0feff !important;
}
.font-10 {
  font-size: 12px;
}
.edit-ic {
  width: 14px;
  position: relative;
  top: -1px;
}
form.form_design .cstom-checkbox {
  text-transform: initial !important;
  font: normal normal 100 16px/30px AvenirLTStd45Book !important;
}
select.form-control:not([size]):not([multiple]) {
  -webkit-appearance: none;
  background-image: url("./assets/img/arrow-down.svg") !important;
  background-position: calc(100% - 15px) !important;
  background-repeat: no-repeat !important;
}
.content-sidebar {
  width: calc(100% - 90px);
  margin-left: 90px;
}
.vaccination-card-list {
  .owl-stage-outer {
    margin: auto;
    text-align: center;
  }
  .owl-item {
    vertical-align: top;
    // max-width: 260px;
    // min-width: 260px;
  }
}
.btn-light-blue {
  background-color: #f0feff;
  text-transform: uppercase;
  padding: 6px 15px 5px 15px;
  min-height: auto;
  border: 1px solid #bffbff;
  border-radius: 20px;
  font-size: 0.675rem;
}
.text-main {
  color: #00b2bb;
}
.custom-pagination .page-link {
  border: none;
  border-radius: 50px !important;
  margin: 0px 3px;
  border: 1px solid rgb(0 178 187 / 35%);
  box-shadow: 0px 0px 10px rgb(0 0 0 / 5%);
}
.custom-pagination .active .page-link {
  background: #00b2bb !important;
}
h2.subheading {
  font-size: 17px;
  font-family: "Francois One";
  letter-spacing: 0.51px;
  color: #282c2c;
  text-transform: uppercase;
}
.input-with-post-icon {
  .form-control[readonly] {
    background: none !important;
    border: none !important;
  }
}
.wrapthebelow {
  .calenderset {
    .form-control[readonly] {
      background: none !important;
      border: none !important;
    }
  }
}
// dashboard-tiles
.dashboard-tiles-wrapper {
  font-family: "AvenirLTStd55Roman";
  margin-left: -10px;
  margin-right: -10px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  flex-wrap: wrap;
  max-width: 870px;
  margin: 0 auto;
  .dashboard-tiles-row {
    width: 100%;
    margin-left: -11px;
    margin-right: -11px;
    margin: auto;
  }
  // .tiles-main{
  //     padding-left: 11px;padding-right: 11px;
  //     width: calc(100% / 4);
  //     display: inline-block;
  //     vertical-align: top;
  // }
  .tiles {
    // height: 138px;
    background-color: #edfeff;
    padding: 40px 25px;
    display: block;
    width: 100%;
    // flex-direction: column;
    align-items: center;
    border-radius: 7px;
    // margin: 0px 10px 20px 10px;
    .tile-icon {
      width: 40px;
      height: 30px;
      display: flex;
      margin: auto;
      align-items: center;
      justify-content: center;
      img.icon {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .tile-content {
      margin-top: 20px;
      color: #5a5a5a;
      font-size: 16px;
      text-align: center;
      font-weight: 500;
    }
  }
}

@media (max-width: 1100px) {
  .dashboard-tiles-wrapper {
    max-width: 780px !important;
    .tiles {
      background-color: #edfeff;
      padding: 30px 15px;
    }
  }
}
@media (max-width: 991px) {
  .dashboard-tiles-wrapper {
    max-width: 700px !important;
  }
}
@media (min-width: 992px) {
  .owl-item {
    max-width: 260px;
  }
}
@media (max-width: 991px) {
  .vaccination-card-list {
    .owl-item {
      max-width: 100%;
    }
  }
  .dashboard-tiles-wrapper {
    padding: 25px 10px;
  }
}
@media (max-width: 767px) {
  .dashboard-tiles-wrapper {
    .tiles-main {
      // width: calc(100% / 2);
      margin-bottom: 22px;
      .tiles {
        padding: 20px;
      }
    }
  }
  div.owl-theme .active {
    float: left !important;
  }
  .custom-pagination .page-link {
    margin: 0px 1px;
    padding: 0.35rem 0.65rem;
  }
  .modal-ct .modal-dialog {
    padding: 1rem;
  }
  .forgot-subheading {
    font: normal normal normal 15px/20px AvenirLTStd45Book !important;
  }
  .left-panel {
    display: none;
  }
  .content a {
    font: normal normal normal 20px/50px Francois One !important;
  }
  .bg-white-mob {
    background: #fff !important;
  }
  .upload-card {
    height: 190px;
  }
  h2.subheading {
    font-size: 16px;
  }
}
@media (max-width: 576px) {
  .btn-light-blue {
    text-transform: capitalize;
    padding: 5px 11px 2px 12px;
    border: 1px solid #bffbff;
    height: auto !important;
    min-height: auto;
  }
  .imgBox {
    width: 100%;
    max-width: 300px;
    margin: auto;
  }
  .cutsom-tab .nav-tabs {
    padding: 0px !important;
  }
  .cutsom-tab .nav-tabs li a {
    font-size: 12px !important;
  }
  .modal-ct .modal-dialog {
    padding: 0.75rem;
  }
  .btn,
  a.btnaddVacine,
  a.btnaddVacine span,
  a.btncreateAccount,
  a.btncreateAccount span {
    font-size: 14px !important;
  }
  .buttondesign {
    width: 100%;
    margin: auto;
  }
}
@media screen and (max-width: 567px) {
  .dashboard-tiles-wrapper {
    .tiles-main {
      .tiles {
        padding: 5px 10px;
      }
      .tile-content {
        font-size: 13px;
        line-height: normal;
      }
    }
  }
  .dashboard-tiles-wrapper {
    .tiles-main {
      width: 100%;
      margin-bottom: 15px;
    }
  }
}
// universal style

.main-wrapper {
  background: #f0feff;
  max-width: 843px;
  margin: auto;
}
// vaccine-record.component css start here
.cutsom-tab {
  .nav-tabs {
    width: 100%;
    display: flex;
    margin-bottom: 40px;
    border-bottom: none;
    padding-left: 15px;
    padding-right: 15px;
    li {
      width: 50%;
      text-align: center;
      .nav-link {
        border: none;
        padding: 12px 0px;
        color: #a0a0a0;
        position: relative;
        font-size: 16px;
        font-family: "AvenirLTStd55Roman";
        &::after,
        &::before {
          content: "";
          height: 4px;
          background-color: #d1f3f5;
          transition: 0.3s ease;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
        }
        &::after {
          width: 0%;
        }
      }
      .active {
        border: none;
        color: #5a5a5a;
        background: none !important;
        &::after {
          background-color: #1db2bb;
          width: 100%;
        }
      }
    }
  }
}

.option-drop {
  .btn {
    padding: 0px;
    outline: none;
    box-shadow: none;
    min-height: auto;
    background: none;
  }
  .dropdown-toggle::after {
    display: none;
  }
  .dropdown-menu {
    border: none;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 6%);
    .dropdown-item {
      padding: 8px 10px;
      font-size: 0.9rem;
      &:hover {
        background: rgb(238 238 238 / 38%);
        color: #1db2bb;
      }
    }
  }
  .more-icon {
    max-width: 5px !important;
  }
}

.dose-card {
  background-image: url("./assets/img/background_dose.png");
  border-radius: 15px;
  background-size: cover;
  background-color: transparent;
  background-repeat: no-repeat;
  color: var(--white);
  font-family: "AvenirLTStd45Book";
  .dose-heading,
  .dose-mid {
    border-bottom: 1px solid rgb(255 255 255 / 19%);
    h4 {
      font-family: Francois One;
      padding-top: 3px;
      padding-bottom: 3px;
      position: relative;
      padding-left: 1rem;
      margin-left: 1rem;
      &::before {
        content: "";
        height: 100%;
        width: 3px;
        border-radius: 5px;
        left: 0;
        top: 0;
        background: #b3df79;
        position: absolute;
      }
    }
  }
  h2,
  .dose-des strong {
    font-family: "AvenirLTStd55Roman";
  }
}
.cert-card {
  border-radius: 10px;
  // overflow: hidden;
  h4 {
    font-family: Francois One;
    color: #282c2c;
    text-transform: uppercase;
    font-size: 17px;
  }
}
.option-drop-left {
  .dropdown-menu {
    left: auto !important;
    right: 0;
  }
}
.btn-white {
  font-family: "AvenirLTStd55Roman";
  border: 1px solid #00b2bb;
  background: var(--white);
  border-radius: 7px;
  color: #696969;
  text-transform: uppercase;
}
.btn-white:hover {
  color: #111;
}
// vaccine-record.component css end here
.owl-theme .owl-stage {
  margin: auto;
}
// .owl-theme .owl-stage .owl-item:last-child{margin-right: 0px !important;}
.owl-theme .active {
  // float: none !important;
  // display: inline-block;
}
.owl-theme .owl-nav {
  position: absolute;
  top: 50%;
  width: 100%;
  z-index: 9;
  .owl-prev,
  .owl-next {
    color: #fff;
    font-size: 14px;
    height: 33px;
    width: 33px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 0;
    background: #00b2bb !important;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px;
    position: absolute;
    left: 5px;
    min-height: auto;
    border-radius: 50%;
  }
  .owl-next {
    right: 5px;
    left: auto;
    transform: rotate(180deg);
  }
}
.vg-icon-play_arrow:before {
  content: "";
  background: url("./assets/img/play-button.png") no-repeat;
  background-size: cover;
  height: 50px;
  width: 50px;
  transition: 0.3s ease;
}
vg-overlay-play .vg-overlay-play {
  opacity: 1 !important;
}
.bg-red {
  background: red;
}

.my-vaccine-card-wrapper {
  background-color: #f0feff;
  display: flex;
  min-height: 260px;
  flex-wrap: wrap;

  .left-tabs {
    width: 248px;
    @media (max-width: 1150px) {
      width: 200px !important;
    }
    @media (max-width: 1100px) {
      width: 170px !important;
    }
    @media (max-width: 767px) {
      width: 100% !important;
    }
    .left-nav {
      padding: 0;
      margin: 0;
      border-right: 1px solid #c2ecef;
      height: 100%;
      .items {
        .item {
          display: block;
          border-bottom: 1px solid #c2ecef;
          padding: 25px 36px;
          color: #838383;
          font-size: 16px;
          font-family: "AvenirLTStd45Book";
          &.active {
            color: #5a5a5a;
            position: relative;
            &::before {
              position: absolute;
              width: 4px;
              height: 33px;
              background-color: #8dcf36;
              content: "";
              left: 17px;
              top: 50%;
              transform: translateY(-50%);
              border-radius: 10px;
            }
          }
        }
        &:last-child {
          .item {
            border-bottom: 0;
          }
        }
      }
    }
  }
  .right-container {
    width: calc(100% - 248px);
    padding: 22px 16px;
    @media (max-width: 1150px) {
      width: calc(100% - 200px);
    }
    @media (max-width: 1100px) {
      width: calc(100% - 170px);
    }
    @media (max-width: 1050px) {
      width: 100%;
    }
  }
}
@media (max-width: 1100px) {
  .left-nav {
    .items {
      .item {
        padding: 22px 25px !important;
        font-size: 15px !important;
        &.active {
          &::before {
            left: 12px !important;
          }
        }
      }
    }
  }
  .exceed-date {
    font-size: 11px;
    svg {
      width: 13px !important;
      height: auto;
    }
  }
}
@media (max-width: 991px) {
  .my-vaccine-card-wrapper {
    .left-tabs,
    .right-container {
      width: 100%;
    }
  }
}
@media (max-width: 1100px) {
  .card-wrapper-vaccine {
    margin-left: -5px;
    margin-right: -5px;
    [class*="col-"] {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  .wrap_button {
    .btn {
      height: 47px !important;
      min-height: 47px !important;
    }
  }
}
@media (max-width: 1024px) {
  .my-vaccine-card-wrapper {
    .left-tabs {
      .left-nav {
        .items {
          .item {
            &.active {
              &::before {
                left: 10px;
              }
            }
          }
        }
      }
    }
  }
  .wrap_button {
    .btn {
      height: 47px !important;
      min-height: 47px !important;
    }
  }
}
.tracker-mesure input {
  height: 15px;
  width: 15px;
  position: relative;
  top: 2px;
}

@import "../node_modules/angular-calendar/css/angular-calendar.css";

mat-select-country
  > mat-form-field.mat-form-field-appearance-outline
  .mat-mdc-text-field-wrapper {
  height: 50px;
  > .mat-mdc-form-field-flex {
    height: 50px;
    > .mdc-notched-outline {
      background-color: #f8f8f8;
      border-radius: 7px;

      > .mdc-notched-outline {
        &__leading,
        &__notch,
        &__trailing {
          border: none;
        }

        &__notch {
          label > mat-label {
            font-size: 15px;
            color: #8e8e8e;
            font-weight: 100;
            letter-spacing: 0px;
            text-transform: capitalize;
          }
        }
      }
    }
    > .mat-mdc-form-field-infix {
      padding: 13px 0;
      min-height: 50px;
      z-index: 1;
    }
  }
}


iframe {
  color-scheme: normal;
}